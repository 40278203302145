import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';

import Component from '../../components/artist';

import useInputs from '../../hooks/useInputs';
import upload, { cvUpload } from '../../library/upload.lib';

export default function Artist() {
  const toast = useRef(null);

  const [artists, setArtists] = useState([]);
  const { inputs, onChange, reset } = useInputs({
    name: '',
    year_of_birth: '',
    country: '',
    about: '',
  });
  const [cv, setCv] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);

  const getArtists = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artist`)
      .then((response) => {
        setArtists(response.data.data);
      });
  };

  const postArtist = async () => {
    const cvArray = [''];
    const thumbnailArray = [''];

    if (cv.length) {
      for (const file of cv) {
        await cvUpload(
          file,
          `${process.env.REACT_APP_ENVIRONMENT}/artist/${inputs.name}`
        )
          .then((s3_response) => {
            cvArray.push(
              `https://leeandbae.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artist/${inputs.name}/${s3_response.timestamp}.pdf`
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    if (thumbnail.length) {
      for (const image of thumbnail) {
        await upload(
          image,
          `${process.env.REACT_APP_ENVIRONMENT}/artist/${inputs.name}`
        )
          .then((s3_response) => {
            thumbnailArray.push(
              `https://leeandbae.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artist/${inputs.name}/${s3_response.timestamp}.jpeg`
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    axios
      .post(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artist`, {
        ...inputs,
        cv: cvArray.length === 2 ? cvArray[1] : cvArray[0],
        thumbnail:
          thumbnailArray.length === 2 ? thumbnailArray[1] : thumbnailArray[0],
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Added Artist.',
            life: 3000,
          });
          getArtists();
          reset();
          setCv([]);
          setThumbnail([]);
        }
      });
  };

  const updateArtist = async () => {
    const cvArray = [''];
    const thumbnailArray = [''];

    if (cv.length) {
      for (const file of cv) {
        await cvUpload(
          file,
          `${process.env.REACT_APP_ENVIRONMENT}/artist/${inputs.name}`
        )
          .then((s3_response) => {
            cvArray.push(
              `https://leeandbae.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artist/${inputs.name}/${s3_response.timestamp}.pdf`
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    if (thumbnail.length) {
      for (const image of thumbnail) {
        await upload(
          image,
          `${process.env.REACT_APP_ENVIRONMENT}/artist/${inputs.name}`
        )
          .then((s3_response) => {
            thumbnailArray.push(
              `https://leeandbae.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/artist/${inputs.name}/${s3_response.timestamp}.jpeg`
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    axios
      .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artist`, {
        ...inputs,
        cv: cvArray.length === 2 ? cvArray[1] : inputs.cv,
        thumbnail:
          thumbnailArray.length === 2 ? thumbnailArray[1] : inputs.thumbnail,
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Updated Artist.',
            life: 3000,
          });
          getArtists();
          reset();
          setCv([]);
          setThumbnail([]);
        }
      });
  };

  const deleteArtist = (id) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artist`, {
        data: {
          id: parseInt(id),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Artist.',
            life: 3000,
          });
          getArtists();
          reset();
        }
      });
  };

  useEffect(() => {
    getArtists();
  }, []);

  return (
    <>
      <Component
        artists={artists}
        inputs={inputs}
        onChange={onChange}
        reset={reset}
        postArtist={postArtist}
        updateArtist={updateArtist}
        deleteArtist={deleteArtist}
        cv={cv}
        setCv={setCv}
        thumbnail={thumbnail}
        setThumbnail={setThumbnail}
      />
      <Toast ref={toast} />
    </>
  );
}
