import cn from 'classnames';
import styled from 'styled-components';
import { Menu } from 'primereact/menu';
import { Link, useNavigate } from 'react-router-dom';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import CryptoJS from 'crypto-js';
import axios from 'axios';

export default function Navigation({ children }) {
  const navigate = useNavigate();

  const toast = useRef(null);

  const [isModal, setIsModal] = useState(false);
  const [password, setPassword] = useState('');

  const handleChange = () => {
    if (password === '') {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please enter your Id or Password.',
        life: 3000,
      });
    }

    const name = JSON.parse(sessionStorage.getItem('user')).name;

    axios
      .put(`${process.env.REACT_APP_SERVER_ADDRESS}/contact/password`, {
        name,
        password: CryptoJS.SHA256(password).toString(),
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'You have changed your password.',
            life: 3000,
          });

          setPassword('');
          setIsModal(false);
        }
      });
  };

  const itemRenderer = (item) => {
    if (item.link) {
      return (
        <div className='p-menuitem-content'>
          <Link
            className='flex align-items-center p-menuitem-link'
            to={item.link}
          >
            <span className={item.icon} />
            <span style={{ margin: '0 10px' }}>{item.label}</span>
          </Link>
        </div>
      );
    }

    return (
      <div className='p-menuitem-content'>
        <div
          className='flex align-items-center p-menuitem-link'
          onClick={() => setIsModal(true)}
        >
          <span className={item.icon} />
          <span style={{ margin: '0 10px' }}>{item.label}</span>
        </div>
      </div>
    );
  };

  const items = [
    {
      template: () => {
        return (
          <div className={cn('logo')}>
            <span className={cn('logo-bold')}>LEE & BAE</span>

            <span className={cn('logo-italic')}>Admin</span>
          </div>
        );
      },
    },
    {
      separator: true,
    },
    { label: 'Home', icon: 'pi pi-home', link: '/', template: itemRenderer },
    {
      label: 'Change Password',
      icon: 'pi pi-lock',
      link: '',
      template: itemRenderer,
    },
    {
      label: 'Art',
      items: [
        {
          label: 'Artist',
          icon: 'pi pi-user',
          link: '/artist',
          template: itemRenderer,
        },
        {
          label: 'Artwork',
          icon: 'pi pi-palette',
          link: '/artwork',
          template: itemRenderer,
        },
        {
          label: 'Exhibition',
          icon: 'pi pi-images',
          link: '/exhibition',
          template: itemRenderer,
        },
        {
          label: 'Online Exhibition',
          icon: 'pi pi-globe',
          link: '/online-exhibition',
          template: itemRenderer,
        },
        {
          label: 'Art Fair',
          icon: 'pi pi-ticket',
          link: '/artfair',
          template: itemRenderer,
        },
        {
          label: 'Publications',
          icon: 'pi pi-book',
          link: '/publications',
          template: itemRenderer,
        },
      ],
    },
    {
      label: 'News',
      items: [
        {
          label: 'Banner',
          icon: 'pi pi-clone',
          link: '/banner',
          template: itemRenderer,
        },
        {
          label: 'Journal',
          icon: 'pi pi-book',
          link: '/journal',
          template: itemRenderer,
        },
      ],
    },
    {
      label: 'Contact',
      items: [
        {
          label: 'Mailing',
          icon: 'pi pi-envelope',
          link: '/mail',
          template: itemRenderer,
        },
        {
          label: 'Inquire',
          icon: 'pi pi-question-circle',
          link: '/inquire',
          template: itemRenderer,
        },
      ],
    },
    {
      template: () => {
        return (
          <div
            className={cn('signout')}
            onClick={() => {
              navigate('/signin');
              sessionStorage.removeItem('user');
            }}
          >
            sign out
          </div>
        );
      },
    },
    {
      template: () => {
        return <div className={cn('copyright')}>© 2024 Gallery LEE & BAE</div>;
      },
    },
  ];

  useEffect(() => {
    const user = sessionStorage.getItem('user');

    if (!user) {
      navigate('/signin');
    }
  }, [navigate]);

  return (
    <>
      <Box>
        <Menu model={items} style={{ width: '250px', height: '100%' }} />

        <div className={cn('menu_contents')}>
          {children}
          <ConfirmDialog draggable={false} />
        </div>
      </Box>
      <Dialog
        draggable={false}
        header='Change Password'
        visible={isModal}
        onHide={() => {
          setIsModal(false);
          setPassword('');
        }}
        style={{
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        <PasswordBox>
          <Password
            placeholder='password'
            feedback={false}
            toggleMask
            className={cn('password_input')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button
            label='Change Password'
            icon='pi pi-lock'
            style={{ width: '100%' }}
            onClick={handleChange}
          />
        </PasswordBox>
      </Dialog>
      <Toast ref={toast} />
    </>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: #f1f3f5;

  .logo {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    &-bold {
      margin-right: 10px;
      font-size: 20px;
      font-weight: 600;
      color: #374151;
    }

    &-italic {
      font-style: italic;
    }
  }

  .signout {
    width: fit-content;
    height: fit-content;
    margin: 20px auto;
    font-size: 16px;
    font-weight: 600;
    color: #e03131;
    cursor: pointer;
    user-select: none;
  }

  .copyright {
    width: 100%;
    height: fit-content;
    text-align: center;
    font-size: 12px;
    margin: 30px auto;
  }

  .menu_contents {
    width: calc(100% - 260px);
    height: 100%;
    margin-left: 10px;
    padding: 0.5rem;
    background: #ffffff;
    color: #4b5563;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
  }
`;

const PasswordBox = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;

  .password_input {
    margin-bottom: 20px;
  }
`;
