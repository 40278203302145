import { useEffect, useRef, useState } from 'react';
import Component from '../../components/journal';
import useInputs from '../../hooks/useInputs';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import upload from '../../library/upload.lib';
import utcDate from '../../library/date.lib';

export default function Journal() {
  const toast = useRef(null);

  const [journalList, setJournalList] = useState([]);
  const [artists, setArtists] = useState([]);
  const [selectArtists, setSelectArtists] = useState([]);
  const [images, setImages] = useState([]);
  const { inputs, onChange, reset } = useInputs({
    title: '',
    location: '',
    link: '',
    video_link: '',
    about: '',
  });
  const [dates, setDates] = useState(null);

  const getArtists = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artist`)
      .then((response) => {
        setArtists(response.data.data);
      });
  };

  const getJournal = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/news/journal`)
      .then((response) => {
        setJournalList(response.data.data);
      });
  };

  const postJournal = async () => {
    if (images.length) {
      await upload(
        images[0],
        `${process.env.REACT_APP_ENVIRONMENT}/journal/${inputs.title}`
      )
        .then((s3_response) => {
          axios
            .post(`${process.env.REACT_APP_SERVER_ADDRESS}/news/journal`, {
              ...inputs,
              artists: selectArtists,
              date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
              image: `https://leeandbae.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/journal/${inputs.title}/${s3_response.timestamp}.jpeg`,
            })
            .then((response) => {
              if (response.data.status === 200) {
                toast.current.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Added Journal.',
                  life: 3000,
                });
                getJournal();
                reset();
                setImages([]);
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });

      return;
    }

    axios
      .post(`${process.env.REACT_APP_SERVER_ADDRESS}/news/journal`, {
        ...inputs,
        artists: selectArtists,
        date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Added Journal.',
            life: 3000,
          });
          getJournal();
          reset();
        }
      });
  };

  const updateJournal = async () => {
    if (images.length) {
      await upload(
        images[0],
        `${process.env.REACT_APP_ENVIRONMENT}/journal/${inputs.title}`
      )
        .then((s3_response) => {
          axios
            .put(`${process.env.REACT_APP_SERVER_ADDRESS}/news/journal`, {
              ...inputs,
              artists: selectArtists,
              date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
              image: `https://leeandbae.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/journal/${inputs.title}/${s3_response.timestamp}.jpeg`,
            })
            .then((response) => {
              if (response.data.status === 200) {
                toast.current.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Updated Journal.',
                  life: 3000,
                });
                getJournal();
                reset();
                setImages([]);
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });

      return;
    }

    axios
      .put(`${process.env.REACT_APP_SERVER_ADDRESS}/news/journal`, {
        ...inputs,
        artists: selectArtists,
        date: `${utcDate(dates[0])}|${utcDate(dates[1])}`,
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Updated Journal.',
            life: 3000,
          });
          getJournal();
          reset();
        }
      });
  };

  const deleteJournal = (id) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_ADDRESS}/news/journal`, {
        data: {
          id: parseInt(id),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Journal.',
            life: 3000,
          });
          getJournal();
          reset();
        }
      });
  };

  useEffect(() => {
    getArtists();
    getJournal();
  }, []);

  return (
    <>
      <Component
        artists={artists}
        journalList={journalList}
        inputs={inputs}
        onChange={onChange}
        reset={reset}
        postJournal={postJournal}
        updateJournal={updateJournal}
        deleteJournal={deleteJournal}
        selectArtists={selectArtists}
        setSelectArtists={setSelectArtists}
        dates={dates}
        setDates={setDates}
        images={images}
        setImages={setImages}
      />
      <Toast ref={toast} />
    </>
  );
}
