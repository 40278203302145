import { useRef } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import Color from 'color';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import useInputs from '../../hooks/useInputs';

import BG_IMG from './bg.jpg';

export default function SignIn() {
  const navigate = useNavigate();

  const toast = useRef(null);

  const { inputs, onChange, reset } = useInputs({
    id: '',
    password: '',
  });

  const handleSignin = () => {
    if (inputs.id === '' || inputs.password === '') {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please enter your Id or Password.',
        life: 3000,
      });
    }

    axios
      .post(`${process.env.REACT_APP_SERVER_ADDRESS}/contact/signin`, {
        name: inputs.id,
        password: CryptoJS.SHA256(inputs.password).toString(),
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'You have logged in.',
            life: 3000,
          });

          navigate('/');

          reset();

          sessionStorage.setItem('user', JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.status === 400) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: error.response.data.message,
            life: 3000,
          });

          return;
        }
      });
  };

  return (
    <>
      <Box>
        <div className={cn('left_panel')}>
          <img src={BG_IMG} alt='bg_img' className={cn('left_panel-img')} />

          <div className={cn('left_panel-blur')}>
            <span
              className={cn('left_panel-blur-text')}
              style={{
                fontSize: '60px',
                fontWeight: '600',
                marginBottom: '50px',
              }}
            >
              LEE & BAE
            </span>
            <span
              className={cn('left_panel-blur-text')}
              style={{
                fontSize: '24px',
                fontWeight: '500',
                marginBottom: '50px',
              }}
            >
              ADMIN SERVICE
            </span>
            <span className={cn('left_panel-blur-text2')}>
              It was developed through primereact.
              <br />
              If you have any problems, please contact the development manager.
            </span>
          </div>
        </div>

        <div className={cn('right_panel')}>
          <InputText
            placeholder='id'
            style={{ width: '300px', marginBottom: '20px' }}
            name='id'
            value={inputs.id}
            onChange={onChange}
          />

          <InputText
            placeholder='password'
            style={{ width: '300px', marginBottom: '30px' }}
            name='password'
            type='password'
            value={inputs.password}
            onKeyDown={(event) => {
              if (event.code === 'Enter') handleSignin();
            }}
            onChange={onChange}
          />

          <Button
            label='Sign In'
            icon='pi pi-sign-in'
            style={{ width: '300px' }}
            onClick={handleSignin}
          />
        </div>
      </Box>
      <Toast ref={toast} />
    </>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .left_panel {
    width: 60%;
    height: 100%;
    position: relative;

    &-img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }

    &-blur {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${Color('#000').alpha(0.4).hsl().toString()};

      &-text {
        color: #fff;
        text-align: center;
        letter-spacing: 3px;
        user-select: none;
      }

      &-text2 {
        color: #ced4da;
        font-size: 18px;
        text-align: center;
        line-height: 1.8;
        font-style: italic;
        user-select: none;
      }
    }
  }

  .right_panel {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
