import { useEffect, useRef, useState } from 'react';
import Component from '../../components/publications';
import useInputs from '../../hooks/useInputs';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import upload from '../../library/upload.lib';

export default function Publications() {
  const toast = useRef(null);

  const [list, setList] = useState([]);
  const [images, setImages] = useState([]);
  const { inputs, onChange, reset } = useInputs({
    title: '',
    sub_title: '',
    text: '',
  });

  const getPublications = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/publications`)
      .then((response) => {
        setList(response.data.data);
      });
  };

  const updateIsPurchases = (id, isPurchases) => {
    axios
      .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/publications`, {
        id,
        is_purchases: isPurchases,
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Updated Publications.',
            life: 3000,
          });

          getPublications();
        }
      });
  };

  const postPublications = async () => {
    await upload(
      images[0],
      `${process.env.REACT_APP_ENVIRONMENT}/publications/${inputs.title}`,
      1500
    )
      .then((s3_response) => {
        axios
          .post(`${process.env.REACT_APP_SERVER_ADDRESS}/art/publications`, {
            ...inputs,
            image: `https://leeandbae.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/publications/${inputs.title}/${s3_response.timestamp}.jpeg`,
          })
          .then((response) => {
            if (response.data.status === 200) {
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Added Art Fair.',
                life: 3000,
              });
              getPublications();
              reset();
              setImages([]);
            }
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updatePublications = async () => {
    if (images.length) {
      await upload(
        images[0],
        `${process.env.REACT_APP_ENVIRONMENT}/publications/${inputs.title}`,
        1500
      )
        .then((s3_response) => {
          axios
            .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/publications`, {
              ...inputs,
              image: `https://leeandbae.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/publications/${inputs.title}/${s3_response.timestamp}.jpeg`,
            })
            .then((response) => {
              if (response.data.status === 200) {
                toast.current.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Updated Publications.',
                  life: 3000,
                });
                getPublications();
                reset();
                setImages([]);
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .put(`${process.env.REACT_APP_SERVER_ADDRESS}/art/publications`, {
          ...inputs,
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Updated Art Fair.',
              life: 3000,
            });
            getPublications();
            reset();
            setImages([]);
          }
        });
    }
  };

  const deletePublications = (id) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_ADDRESS}/art/publications`, {
        data: {
          id: parseInt(id),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Publications.',
            life: 3000,
          });
          getPublications();
          reset();
        }
      });
  };

  useEffect(() => {
    getPublications();
  }, []);

  return (
    <>
      <Component
        list={list}
        inputs={inputs}
        onChange={onChange}
        reset={reset}
        postPublications={postPublications}
        updatePublications={updatePublications}
        deletePublications={deletePublications}
        images={images}
        setImages={setImages}
        updateIsPurchases={updateIsPurchases}
      />
      <Toast ref={toast} />
    </>
  );
}
