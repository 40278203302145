import AWS from 'aws-sdk';
import Resizer from 'react-image-file-resizer';

AWS.config.update({
  region: 'ap-northeast-2',
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_AWS_S3_ID,
  }),
});

const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
});

const resizeFile = (file, size) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      size,
      size,
      'JPEG',
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });

export function deleteS3(path) {
  return new Promise((resolve, reject) => {
    s3.deleteObject(
      {
        Bucket: 'leeandbae',
        Key: `${path}.jpeg`,
      },
      (error, data) => {
        if (error) {
          reject(error);
        }

        resolve(data);
      }
    );
  });
}

export default function upload(file, path, size = 3000) {
  return new Promise((resolve, reject) => {
    const timestamp = new Date().getTime();

    resizeFile(file, size).then((uri) => {
      s3.upload(
        {
          Bucket: 'leeandbae',
          Key: `${path}/${timestamp}.jpeg`,
          Body: uri,
          ContentType: 'image/jpeg',
        },
        (error, data) => {
          if (error) {
            reject(error);
          }

          resolve({ timestamp: String(timestamp), data });
        }
      );
    });
  });
}

export function cvUpload(file, path) {
  return new Promise((resolve, reject) => {
    const timestamp = new Date().getTime();

    s3.upload(
      {
        Bucket: 'leeandbae',
        Key: `${path}/${timestamp}.pdf`,
        Body: file,
        ContentType: 'application/pdf',
      },
      (error, data) => {
        if (error) {
          reject(error);
        }

        resolve({ timestamp: String(timestamp), data });
      }
    );
  });
}

export function videoUpload(file, path) {
  return new Promise((resolve, reject) => {
    const timestamp = new Date().getTime();

    s3.upload(
      {
        Bucket: 'leeandbae',
        Key: `${path}/${timestamp}.mp4`,
        Body: file,
        ContentType: 'application/mp4',
      },
      (error, data) => {
        if (error) {
          reject(error);
        }

        resolve({ timestamp: String(timestamp), data });
      }
    );
  });
}
